/** @jsx jsx */
import { jsx, Button, Flex, Box, Grid } from "theme-ui"
import { darken, lighten } from "@theme-ui/color"
import { Global } from "@emotion/core"
import React, { useState, useEffect } from "react"
import NavMenuDesktop from "../components/navMenuDesktop"
import NavMenuMobile from "../components/navMenuMobile"
import { Masonry } from "masonic"
import Img from "gatsby-image"
import BackgroundImg from "gatsby-background-image"
import Modal from "react-modal"
import {
  FaTimes,
  FaInfoCircle,
  FaMicrophone,
  FaPlayCircle,
} from "react-icons/fa"
import Footer from "../components/footer"
import { Link } from "gatsby"
import ReactPlayer from "react-player/lazy"
import SEO from "../components/seo"

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "transparent",
    border: "none",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.90)",
    zIndex: 20,
  },
}
// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#___gatsby")

const ShowreelsPage = props => {
  return (
    <>
      <Global
        styles={theme => ({
          ".ReactModal__Body--open": {
            overflow: "hidden",
          },
        })}
      />
      <SEO title="Showreels" />
      <NavMenuMobile />
      <Grid
        gap={2}
        columns={["none", "2fr 1fr", "3fr 1fr"]}
        sx={{
          bg: "primaryBG",
          px: 3,
        }}
      >
        <section sx={{ minHeight: "100vh" }}>
          <div>
            <h1
              sx={{
                color: "primary",
                mt: ["80px", 2],
              }}
            >
              {props.data.showreels.frontmatter.title}
            </h1>
            {/* <BackgroundImg
              fluid={
                props.data.showreels.frontmatter.feat_img.childImageSharp.fluid
              }
              sx={{
                height: ["200px", "275px", "350px"],
              }}
            /> */}
          </div>
          <Flex
            sx={{
              marginTop: 4,
              display: ["block", "flex"],
            }}
          >
            <div
              sx={{
                bg: "white",
                padding: 3,
                width: ["100%", "60%"],
                marginRight: [0, "5%"],
                marginBottom: [3, 4],
              }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: props.data.showreels.html,
                }}
              />
            </div>
          </Flex>
          {props.data.showreels.frontmatter.reels.map(reel => {
            //height/width*100
            let aspectRatio = undefined
            if (reel.aspectRatio == "short") {
              aspectRatio = "41.5%"
            } else {
              aspectRatio = "56.25%"
            }
            console.log(reel.video);
            return (
              <Box
              
                sx={{
                  bg: "white",
                  padding: 2,
                  mb: 3,
                }}
              >
                <div
                  className="player-wrapper"
                  sx={{
                    position: "relative",
                    paddingTop: aspectRatio,
                    mb: 3,
                    bg: "black",
                  }}
                >
                  <ReactPlayer
                    className="react-player"
                    config={{ vimeo: { playerOptions: { controls: 1 } } }}
                    url={reel.video}
                    width="100%"
                    height="100%"
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                    }}
                  />
                </div>
                <div>
                  <h1>{reel.title}</h1>
                  <p>{reel.description}</p>
                </div>
              </Box>
            )
          })}
        </section>
        <section>
          <NavMenuDesktop navPosition="right" />
        </section>
      </Grid>
      <Footer navPosition="right" />
    </>
  )
}

export default ShowreelsPage

export const query = graphql`
  query {
    showreels: markdownRemark(
      frontmatter: { templateKey: { eq: "page-showreels" } }
    ) {
      id
      frontmatter {
        title
        reels {
          title
          description
          video
          aspectRatio
        }
        feat_img {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
      html
    }
  }
`
